<template>
	<div>
	<ContentHeader title="Data Jurnal Singkat"  />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Jurnal Singkat</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-data-jurnal-singkat"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getJurnalSingkat">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            				</div>
	            				<div class="col-lg-8"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>
							      <th scope="col" class="text-sm">JENIS</th>
							      <th scope="col" class="text-sm">AKUN DEBIT</th>
							      <th scope="col" class="text-sm">AKUN KREDIT</th>
							      <th scope="col" class="text-sm">DESKRIPSI</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in jurnal_singkat" :key="row.id">
							    	<td>{{ index+1 }}</td>
							    	<td>{{ row.jenis }}</td>
							    	<td>{{ row.akun_debit.kode }} - {{ row.akun_debit.nama }}</td>
							    	<td>{{ row.akun_kredit.kode }} - {{ row.akun_kredit.nama }}</td>
							    	<td>{{ row.deskripsi }}</td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import axios from 'axios'

export default{
	components: {
		ContentHeader: ContentHeader,
	},
    setup(){
		const user = computed(() => store.getters['auth/user'])
		const jurnal_singkat = ref([])
		const memuat_data = ref(true)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])
		const cek_data = ref(false)

		const getJurnalSingkat = async () => {
			jurnal_singkat.value = []
	    	memuat_data.value = true

			let { data } = await axios.get(`api/jurnal_singkat/get/${user.value.cabang_id}/${page.value}`)

			if (data != 'kosong') {
				memuat_data.value = false
				jurnal_singkat.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
    			cek_data.value = false
			} else {
				memuat_data.value = false
				cek_data.value = true
			}
		}

		const paginateData = async (url) => {

    		if (url != null) {
    			jurnal_singkat.value = []
	    		memuat_data.value = true

	    		let link_url = new URL(url)
	    		link_url = `${link_url.pathname}${link_url.search}`
	    		
	    		let { data } = await axios.get(`${link_url}`)

	    		
	    		if (data != 'kosong') {
	    			memuat_data.value = false
	    			jurnal_singkat.value = data.data	
	    			links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    		}
    		}
    	}

		onMounted(() => {
			getJurnalSingkat()
		})

		return { jurnal_singkat, memuat_data, links, paginateData, pagination, getJurnalSingkat, page, cek_data }

    }
}
</script>

<style>
	
</style>